/* Carousel indicators*/
.carousel-indicators{
    position: relative;
}

.carousel .carousel-indicators button {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: #fff;
    border: 1px solid #357fd8;
}
.carousel .carousel-indicators .active {
    background-color: #357fd8;
}

/* Carousel controls*/
.carousel {
    margin: 1rem;
}
.carousel-inner {
    height: auto;
}

.carousel-control-next-icon {
    background-image: url("../assets/icons/rightArrow.svg")!important;
}

.carousel-control-prev-icon {
    background-image: url("../assets/icons/leftArrow.svg")!important;
}

.section-title h2{
    color: #357fd8;
    font-weight: 700;
    font-size: 2.7rem;
}

/* xs */
@media only screen and (max-width: 576px) {
    .carousel-control-next-icon {
        margin-right: -100px;
    }
    .carousel-control-prev-icon {
        margin-left: -100px;
    }
    .section-title h2{
        font-size: 1.9rem;
    }
}
/* s */
@media only screen and (min-width: 576px) {
    .carousel-control-next-icon {
        margin-right: -120px;
    }
    .carousel-control-prev-icon {
        margin-left: -120px;
    }
}
/* md */
@media only screen and (min-width: 768px) {
    .carousel-control-next-icon {
        margin-right: -150px;
    }
    .carousel-control-prev-icon {
        margin-left: -150px;
    }
}
/* l */
@media only screen and (min-width: 992px) {
    .carousel-control-next-icon {
        margin-right: -190px;
    }
    .carousel-control-prev-icon {
        margin-left: -190px;
    }
}
/* xl */
@media only screen and (min-width: 1200px) {
    .carousel-control-next-icon {
        margin-right: -200px;
    }
    .carousel-control-prev-icon {
        margin-left: -200px;
    }
}

/* xxl */
@media only screen and (min-width: 1500px) {
    .carousel-control-next-icon {
        margin-right: -240px;
    }
    .carousel-control-prev-icon {
        margin-left: -240px;
    }
}

/* Cards */
.latestNews-section .mobile-carousel .card{
    width: 100%;
    border: 0;
    text-align: center;
    border-radius: 4%;
 }

.latestNews-section .mobile-carousel .card .card-img-top{
    height: 230px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-top-left-radius: 4%;
    border-top-right-radius: 4%;
}

.latestNews-section .mobile-carousel .card .card-body .card-title{
    color: #05264D;
    font-weight: bold;
}

.latestNews-section .mobile-carousel .card .card-body .card-date{
    color: #357FD8;
   }

.latestNews-section .mobile-carousel .card a,
.latestNews-section .mobile-carousel .card a:link,
.latestNews-section .mobile-carousel .card a:visited,
.latestNews-section .mobile-carousel .card a:hover,
.latestNews-section .mobile-carousel .card a:active{
    text-decoration: none;
    color: #000000;
}


