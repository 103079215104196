/* Banner */

.businessList .businessList-section-content .banner{
    height: 300px;
    width: 100%;
    background-image: url('../../assets/images/contactus_banner.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.businessList .businessList-section-content .banner .section-title {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
}

/* xs banner mobile*/
@media only screen and (max-width: 576px) {
    .businessList .businessList-section-content .banner{
        height: 300px;
        width: 100%;
        background-image: url('../../assets/images/contactus_banner_mobile.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .businessList .businessList-section-content .banner .section-title {
        padding-left: 5%;
    }

    .businessList .businessList-section-content .banner .section-title h6{
        font-size: 0.8rem;
    }
}

/* Search bar */

@media only screen and (max-width: 575px) {
    .businessList .businessList-section-content .searchBar{
    padding-top:4rem;
    }
}

@media only screen and (max-width: 768px) {
    .businessList .businessList-section-content .searchBar{
        padding-top:3rem;
    }
}

@media only screen and (min-width: 768px) {
    .businessList .businessList-section-content .searchBar{
        padding-top:1rem;
    }
}

/* Results */
.businessList .businessList-section-content .results{
    padding-top: 150px;
    padding-bottom: 100px;
}
@media only screen and (min-width: 768px) {
    .businessList .businessList-section-content .results {
        padding-top: 130px;
    }
}

@media only screen and (min-width: 990px) {
    .businessList .businessList-section-content .results {
        padding-top: 100px;
    }
}

.businessList .businessList-section-content .results .filters{

}

.businessList .businessList-section-content .results .filters p{
    font-weight: bold;
    color: #05264d;
}


/* form */

.businessList .businessList-section-content .filters-form{
    background-color: #f5f5f5;
    border-radius: 3%;
    margin-bottom: 2rem;
}

.businessList .businessList-section-content .filters-form h4{
    color: #00061d;
    font-weight: bold;
}

/* Form inputs*/
.businessList .businessList-section-content .filters-form form .input-info,
.businessList .businessList-section-content .filters-form form textarea{
    width: 100%;
    margin-bottom: 20px;
    outline-color: #4b9c4e;
}

/* inputs & textarea */
.businessList .businessList-section-content .filters-form form select,
.businessList .businessList-section-content .results select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #fff;
    background-image: url("../../assets/icons/downArrow.svg")!important;
    background-repeat: no-repeat;
    background-position-x: 90%;
    background-position-y: 50%;
}

.businessList .businessList-section-content .results .top{
    margin-bottom: 1rem;
    align-items: center;
}

.businessList .businessList-section-content .filters-form form .input-info,
.businessList .businessList-section-content .results .top select{
    height: 55px;
}
.businessList .businessList-section-content .results .top select{
    width: 300px;
}

.businessList .businessList-section-content .results .top p{
    font-weight: bold;
    color: #05264d;
}

.businessList .businessList-section-content .filters-form form .input-info,
.businessList .businessList-section-content .filters-form form textarea,
.businessList .businessList-section-content .results .top select{
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    border: 1px solid #8d8d8d;
    padding-left: 25px;
}

/* Range */
.businessList .businessList-section-content .filters-form form input[type=range]{
    -webkit-appearance: none;
    width: 100%;
    background: transparent;
    border-color: transparent;
    color: transparent;
}
.businessList .businessList-section-content .filters-form form datalist {
    display: flex;
    justify-content: space-between;
}

.businessList .businessList-section-content .filters-form .submit-filters{
    padding-top: 8px;
}

/* Range Thumb */
.range-fieldset p{
    margin-bottom: 40px;
}

.MuiSlider-markLabel:nth-last-child(2){
     left:93%!important;
}

.MuiSlider-thumb{
    margin-top: -10px;
    -webkit-appearance: none;
    background-color: transparent!important;
    box-shadow: none!important;
    background-image: url("../../assets/icons/downArrow.svg")!important;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 20px!important;
    width: 35px;
    cursor: pointer;
    box-sizing: inherit;
    color: #ffffff!important;
    border-radius: 0!important;
}

.MuiSlider-valueLabel{
    background-color: #fff!important;
    border-radius: 25px!important;
    box-shadow: 3px 3px 3px #9ca3af!important;
    color: #4b5563!important;
    margin-top: 9px;
}

.MuiSlider-valueLabel:before{
 display: none!important;
}


.MuiSlider-thumb:before{
    box-shadow: none!important;
    height: 20px!important;
    width: 35px!important;
}

/* Range track */
.MuiSlider-track{
    width: 100%!important;
    height: 3px!important;
    cursor: pointer!important;
    background: #dbdbdb!important;
    color: transparent!important;
    border: none!important;
}

/* Data card */

.businessList .businessList-section-content .data-card{
    padding: 0 3rem 2rem 3rem;
    border-radius: 15px;
    margin-bottom: 2rem;
}

.businessList .businessList-section-content .data-card .state{
   width: 200px;
   border-bottom-left-radius: 15px;
   border-bottom-right-radius: 15px;
   text-align: center;
   color: #fff;
   font-weight: bold;
   margin-bottom: 1rem;
   padding: 5px;
}

.businessList .businessList-section-content .data-card .state-sold{
    background-color: #f54242;
}

.businessList .businessList-section-content .data-card .state-available{
    background-color: #60f542;
}

.businessList .businessList-section-content .data-card .state-reserved{
    background-color: #f58a42;
}

.businessList .businessList-section-content .data-card h4,
.businessList .businessList-section-content .data-card h6{
    font-weight: bold;
    color: #05264d;
}

.businessList .businessList-section-content .data-card .detail-button{
    text-align: right;
}

/* Pagination */

.custom-pagination{
   display: grid;
  justify-items: center;
    width: 100%;
}

.MuiPaginationItem-root{
    width: 100%;
}

.MuiPaginationItem-previousNext{
    -webkit-appearance: none;
    color: transparent!important;
    width: 10px;
}

.MuiPaginationItem-previousNext:hover{
    background-color: transparent!important;
}

.MuiPagination-ul li:last-of-type{
    background-image: url("../../assets/icons/rightArrow.svg")!important;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
    width: 15px;
}

.MuiPagination-ul li:first-child{
    background-image: url("../../assets/icons/leftArrow.svg")!important;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
    width: 15px;
}

.Mui-selected{
    background-color: transparent!important;
    font-weight: bold!important;
    color: #05264d!important;
    font-size: 1.6rem!important;
}