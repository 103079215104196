/* Background effect */
.contactus .contactus-section-content .back-effect{
    width:100%;
    height:300px;
    background-position: center right;
    background-image: url(../../assets/images/contact-back.png);
    background-repeat: no-repeat;
    background-size: cover;
    bottom:0;
    margin-top: -300px;
}

.contactus-title{
    font-size: 1.8rem;
}

h2.contactus_page_subtitle {
    color: #00061d !important;
    margin-top: 0;
    margin-bottom: 1.2rem;
    font-weight: 700 !important;
    /*font-size: 1.65rem !important;*/
    line-height: normal;
}

/* responsive effect size */
/* xs */
@media only screen and (max-width: 576px) {
    h2.contactus_page_subtitle {
        font-size: 1.4rem !important;
    }
}

/* md */
@media only screen and (min-width: 768px) {
    .contactus .contactus-section-content .back-effect{
        height:180px;
        margin-top: -180px;
    }
    
    h2.contactus_page_subtitle {
        font-size: 1.52rem !important;
        margin-bottom: 0.55rem;
    }
}
/* l */
@media only screen and (min-width: 992px) {
    .contactus .contactus-section-content .back-effect{
        height:230px;
        margin-top: -230px;
    }
    .contactus-title{
        font-size: 2.7rem;
    }

    h2.contactus_page_subtitle {
        font-size: 1.58rem !important;
    }
}
/* xl */
@media only screen and (min-width: 1200px) {
    .contactus .contactus-section-content .back-effect{
        height:250px;
        margin-top: -250px;
    }

    h2.contactus_page_subtitle {
        font-size: 1.65rem !important;
    }
}

/* xxl */
@media only screen and (min-width: 1500px) {
    .contactus .contactus-section-content .back-effect{
        height:300px;
        margin-top: -300px;
    }
}

.contactus .contactus-section-content .banner{
    height: 300px;
    width: 100%;
    background-image: url('../../assets/images/contactus_banner.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.contactus .contactus-section-content .banner .section-title {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
  }

/* xs banner mobile*/
@media only screen and (max-width: 576px) {
    .contactus .contactus-section-content .banner{
        height: 300px;
        width: 100%;
        background-image: url('../../assets/images/contactus_banner_mobile.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .contactus .contactus-section-content .banner .section-title {
      padding-left: 5%;
    }

    .contactus .contactus-section-content .banner .section-title h6{
        font-size: 0.8rem;
    }
}

.contactus .contactus-section-content .contact-form{
    padding-left: 10%;
}

.contactus .contactus-section-content .contact-info .info p{
    font-weight: bold;
}

/* form */
.contactus .contactus-section-content .contact-form h4{
    color: #00061d;
    font-weight: bold;
}

/* Form inputs*/
.contactus .contactus-section-content .contact-form form .input-info,
.contactus .contactus-section-content .contact-form form textarea{
   width: 80%;
   outline-color: #4b9c4e;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    border: 1px solid #8d8d8d;
    padding-left: 25px;
}

.contactus .contactus-section-content .contact-form form select{
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #fff;
    background-image: url("../../assets/icons/downArrow.svg")!important;
    background-repeat: no-repeat;
    background-position-x: 90%;
    background-position-y: 50%;

}

.contactus .contactus-section-content .contact-form form .error-message{
    z-index: 999;
    position: absolute;
    font-size: 12px;
    font-weight: bold;
   padding-left: 25px;
    margin-top: -3px;
}

.contactus .contactus-section-content .contact-form form .error{
    border:1px solid red!important;
    outline-color: red;
    }

.contactus .contactus-section-content .contact-form form .success{
    border:1px solid green!important;
    outline-color: green;
}

/* xs form mobile */
@media only screen and (max-width: 576px) {
    .contactus .contactus-section-content .contact-form form .input-info,
    .contactus .contactus-section-content .contact-form form textarea{
        width: 100%;
    }

    .contactus .contactus-section-content .contact-form{
        padding-left: 0;
    }

}

/* inputs & textarea */
.contactus .contactus-section-content .contact-form form textarea{
    height: 250px;
    padding-top: 20px;
}

.contactus .contactus-section-content .contact-form form .input-info{
    height: 55px;
    padding-top: 15px;
}

/* display del Label & placeholder */
.contactus .contactus-section-content .contact-form form fieldset{
    position:relative;
    margin-bottom: 18px;
}

.contactus .contactus-section-content .contact-form form .input-info,
.contactus .contactus-section-content .contact-form form textarea{

  }

.contactus .contactus-section-content .contact-form form fieldset .label-element{
    position:absolute;
    top:5px;
    left:5px;
    font-size:12px;
    color:#666;
    padding-left: 25px;
    font-weight: bold;
 }

.contactus .contactus-section-content .contact-form form .label-check{
    position:absolute;
   top:0px;
    padding-left: 8px;
 }

/* Checkbox */
.contactus .contactus-section-content .contact-form form input[type=checkbox] {
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    border: 1px solid #8d8d8d;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.contactus .contactus-section-content .contact-form form input[type=checkbox]:checked{
    background-color: #4b9c4e;
}

/* posición del botón submit */
.contactus .contactus-section-content .contact-form form .text-right{
    text-align: right;
    width: 80%;
    padding-top: 25px;
}

input[type="number"] {
    -moz-appearance: textfield;
  }
input[type="number"]:hover,
input[type="number"]:focus {
    -moz-appearance: number-input;
}





