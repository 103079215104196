@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

.welcome-section {
    height: 660px;
    background-image: url('../assets/images/welcome.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.welcome-section-content {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;

}
.welcome-section-content-flyer h6{
    color: #91c131;
    font-weight: 300;
    // line-height: 1.7rem;
}
.welcome-section-content-title h1{
    color: #357fd8;
    font-weight: 700;
    font-size: 2.8rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.welcome-section-content-subtitle h6{
    margin-top: 1rem;
    color: #00061d;
    font-weight: 200;
    // line-height: 1.7rem;
}

.welcome-section-content-subtitle h2{
    margin-top: 1rem;
    font-size: 1.2rem;
    color: #00061d;
    line-height: 1.5rem;
    font-weight: lighter;
}


@include media-breakpoint-down(sm) {
    .welcome-section {
        height: 65vh;
        min-height: 580px;
        background-image: url('../assets/images/welcome_mobile.webp');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    .welcome-section-content-subtitle h6{
        font-size: 0.9rem;
    }
    .welcome-section-content-flyer h6{
        font-size: 0.9rem;
    }
    .welcome-section-content-title h1{
        font-size: 1.8rem;
    }
    .welcome-section-content-subtitle h2{
        font-size: 0.9rem;
    }
}