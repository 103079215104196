@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

.git-c{
    padding-top: 2rem;
    // padding-bottom: 1.2rem;
}
.git-c1-image{
    width: 110%;
    align-self: center;
}
.background-gradient {
    background: rgb(78,192,237);
    background: linear-gradient(0deg, rgba(78,192,237,0.10999999821186066) 0%, rgba(255,255,255,1) 30%);
}
.custom.section-title h2{
    color: #357fd8;
    font-weight: 700;
    font-size: 2.7rem;
}
@include media-breakpoint-down(md) {
    .git-c1{
        padding-top: 5rem;
        //padding-bottom: 4rem;
        padding-left: 0px;
        padding-right: 0px;
    }
    .row-git-c1{
        flex-direction: column-reverse;
    }
    .custom.section-title h2{
        font-size: 1.9rem;
    }
}

@media only screen and (min-width: 1100px) {
  .git-c .fix-button-link{
      width: 75%!important;
  }
}