/* Imports */
// Override default variables before the import
$font-size-base: 1.1rem;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

.container {
    padding: 0px;
}

// TITULOS
.section-title h6:first-of-type {
    color: #91c131;
    letter-spacing: 10px;
    line-height: 1.7rem;
    font-size: 1rem;
}

.section-title h1, h2{
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
    color: #357fd8;
    font-weight: bold;
    font-weight: 700;
    line-height: 4rem;
}
.section-title u{
    text-decoration-color: #91c131;
}
// BOTONES
.g-action-button{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    color: #fff;
    background: #8fc032;
    overflow: hidden;
    white-space: nowrap;
    border: 0px;
    height: 3.2rem;
    border-radius: 1.6rem;
    padding-left: 2.2rem;
    padding-right: 2.2rem;
    justify-content: center;
    align-items: center;
    background: -webkit-linear-gradient(to bottom, #8fc032, #147f66);
    background: linear-gradient(to bottom, #8fc032, #147f66)
}
.g-action-button-outline{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    color: #8fc032;
    border-color: #8fc032;
    border-width: 2px;
    border-style: solid;
    background-color: white;
    height: 3.2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    justify-content: center;
    align-items: center;
    overflow: auto;
    white-space: nowrap;
}

@include media-breakpoint-down(md) {
    .container {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .section-title h1, h2{
        font-weight: 700;
        line-height: 3rem;
    }
}