#termsModal .modal-body .terms-text-content p{
    text-align: justify;
    padding-right: 5px;
    text-indent: 1em;
    }

#termsModal .modal-body .terms-text-content h4{
    color: #357fd9;
    text-decoration: underline;
}

#termsModal .modal-body .terms-text-content ol{
    list-style-type: lower-alpha;
}

#termsModal .modal-header{
    background-color: #05264d;
    color: #fff;
}

#termsModal .modal-footer button{
    width: 100%;
}

@media (min-width: 600px) {
    #termsModal .modal-footer button {
        width: 30%;
    }
}