@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

.htb {
    //background-color: rgb(238, 243, 243);
    background-color: #fff;

}
.htb-card {
    background-color: white;
    border-top-width: 0px;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
}
.htb-card-header {
    border-bottom-width:  0px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-end;
    background-color: white;
    padding-top: 5rem;
}
.htb-card-img {
    width: 45%;
    position: absolute;
    top: -30%;
    left: 10%;
}
.htb-card-number{
    color:rgb(156, 177, 226);
    font-size: 3.7rem;
    font-weight: bold;
}

.section-title h2{
    font-size: 2.7rem;
}

h2.htb_page_subtitle {
    color: #00061d !important;
    margin-top: 0;
    margin-bottom: 1.2rem;
    font-weight: 400 !important;
    font-size: 1.1rem !important;
    line-height: inherit;
}

@include media-breakpoint-down(md) {
    .htb {
        //background-color: rgb(212, 212, 212);
        background-color: #fff;
    }
    .htb-card-header {
        padding-top: 2rem;
    }
    .htb-card-number{
        font-size: 4rem;
    }
    .section-title h2{
        font-size: 1.9rem;
    }
}


@media only screen and (max-width: 360px) {
    .htb-card-img {
        width: 35%;
        position: absolute;
        top: -10%;
        left: 10%;
    }
}

@media only screen and (min-width: 450px) {
    .htb-card-img {
        width: 30%;
        position: absolute;
        top: -25%;
        left: 10%;
    }
}

@media only screen and (min-width: 768px) {
    .htb-card-img {
        width: 22%;
        position: absolute;
        top: -25%;
        left: 10%;
    }
}

@media only screen and (min-width: 992px) {
    .htb-card-img {
        width: 45%;
        position: absolute;
        top: -7%;
        left: 10%;
    }
}

@media only screen and (min-width: 1400px) {
    .htb-card-img {
        width: 30%;
        position: absolute;
        top: -7%;
        left: 10%;
    }
}