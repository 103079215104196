@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

.wwa-c{
    padding-top: 2rem;
    padding-bottom: 1.2rem;
}
.wwa-c1-image{
    width: 80%;
    align-self: center;
}
.wwa.section-title h2{
    font-weight: 700;
    font-size: 2.7rem;
}
@include media-breakpoint-down(md) {
    .wwa-c1{
        padding-top: 5rem;
        padding-bottom: 4rem;
        padding-left: 0px;
        padding-right: 0px;
    }
    .wwa.section-title h2{
        font-size: 1.8rem;
    }
}