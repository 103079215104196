.payment .payment-section-content .prices {
    /*background-color: #edebeb;*/
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.payment .payment-section-content .prices h2 {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
    color: #357fd8;
    font-weight: 700;
    line-height: 2rem;
    text-align: center;
}

.payment .payment-section-content .prices p {
    text-align: center;
}

.payment .payment-section-content .conditions-list {
    display: grid;
    justify-content: center;
    padding: 0 15%;
}

.payment .payment-section-content .conditions-list ul {
    list-style: none;
    padding: 0;
    line-height: 2rem;
}

.payment .payment-section-content .conditions-list ul li .custom-bullet {
    background: #8fc032;
    border-radius: 50%;
    display: inline-block;
    margin-right: 6px;
    height: 12px;
    width: 12px;
}

/* Cards */
.payment .payment-section-content .card {
    width: 100%;
    border: 0;
    text-align: center;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.payment .payment-section-content .card .card-top {
    background: linear-gradient(to right, #357fd8, #8fc032);
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.payment .payment-section-content .card .card-top h6 {
    color: #fff;
    font-weight: bold;
}

.payment .payment-section-content .card .card-body .card-title {
    color: #05264d;
    font-weight: bold;
}

.payment .payment-section-content .card .card-body h2 {
    margin: 1rem 0;
}

.fix-button-link {
    display: grid;
    align-items: center;
}

.htb-card-number {
    color: rgb(156, 177, 226);
    font-size: 3.7rem;
}

.icon-check {
    color: green;
    font-size: 1.2rem;
}

.icon-cross {
    color: red;
    font-size: 0.9rem;
}

th.bgd-color-sdt-green {
    background: rgb(107, 156, 15);
    background: linear-gradient(
        0deg,
        rgba(107, 156, 15, 1) 0%,
        rgba(143, 192, 50, 1) 100%
    );
}

th.bgd-color-sdt-blue {
    background: rgb(35, 154, 210);
    background: linear-gradient(
        0deg,
        rgba(35, 154, 210, 1) 0%,
        rgba(73, 182, 234, 1) 100%
    );
}

td.services {
    background: rgb(35, 154, 210);
    background: linear-gradient(
        0deg,
        rgba(35, 154, 210, 1) 0%,
        rgba(73, 182, 234, 1) 100%
    );
    font-size: 1rem !important;
    color: white;
}

th.headerTitle {
    border-top-left-radius: 1rem;
    border-bottom-right-radius: 0.5rem;
    border-top-right-radius: 1rem;
    border-bottom-left-radius: 0.5rem;
    background: rgb(107, 156, 15);
    background: linear-gradient(
        0deg,
        rgba(107, 156, 15, 1) 0%,
        rgba(143, 192, 50, 1) 100%
    );
    padding: 0.7rem 0 !important;
    font-size: 1rem !important;

    color: white;
}

th.headerSubtitle {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    background: linear-gradient(to right, #357fd8, #8fc032);
}

div.prices th,
div.prices td {
    white-space: pre-line;
    /*font-size: 0.9rem;*/
    padding: 0.1rem;
    text-align: center;
    vertical-align: middle;
}

.tablesAclaration {
    font-size: 0.7rem;
}

@media (max-width: 576px) {
    th.headerTitle {
        border-top-left-radius: 0.7rem;
        border-bottom-right-radius: 0.3rem;
        border-top-right-radius: 0.7rem;
        border-bottom-left-radius: 0.3rem;
        padding: 0.3rem 0 !important;
        font-size: 0.7rem !important;
    }

    th.headerSubtitle {
        border-top-left-radius: 0.7rem;
        border-top-right-radius: 0.7rem;
    }

    div.prices.table-responsive {
        overflow-x: auto;
        width: fit-content;
    }

    div.prices th,
    div.prices td {
        font-size: 0.5rem;
    }

    .icon-check {
        font-size: 0.75rem;
    }

    .icon-cross {
        font-size: 0.5rem;
    }

    .tablesAclaration {
        font-size: 0.42rem;
    }

    td.services {
        font-size: 0.7rem !important;
        color: white;
    }
}

@media (min-width: 577px) {
    div.prices th,
    div.prices td {
        font-size: 0.7rem;
    }
}

@media (min-width: 1024px) {
    div.prices th,
    div.prices td {
        font-size: 0.9rem;
    }
}
