.suscribe {
    height: 420px;
    background-image: url('../assets/images/suscribe.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.suscribe-title {
    color: white;
}
.suscribe-overlay {
    background-color: #000a16ea;
    height: 100%;
}
.suscribe-input {
    height: 3.2rem;
}