
.whatsapp-button{
    position: fixed;
    bottom: 20px;
     z-index:999!important;
    right: 20px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;

    padding: 15px;
    border-radius: 50px;
    background: rgb(39, 211, 102);
    transition: 0.4s;
    animation: buttonW 2s infinite;
}


.whatsapp-button svg{
    color: white;
    width: 40px;
    height: 40px;
  }

@keyframes buttonW {
    0% {transform: scale(1)}
    50% {transform: scale(1.1)}
    100% {transform: scale(1)}
}