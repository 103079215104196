@import url('../../../node_modules/bootstrap-icons/font/bootstrap-icons.css');

.quotation .quotation-section-content .quotation-form{
    padding: 3% 5%;
}

.quotation .quotation-section-content .quotation-form h4{
    color: #00061d;
    font-weight: bold;

}

/* Form inputs*/
.quotation .quotation-section-content .quotation-form form .input-info,
.quotation .quotation-section-content .quotation-form form textarea{
    width: 100%;
    outline-color: #4b9c4e;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    border: 1px solid #8d8d8d;
    padding-left: 25px;
}

.quotation .quotation-section-content .quotation-form form select{
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #fff;
    background-image: url("../../assets/icons/downArrow.svg")!important;
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 50%;

}

.quotation .quotation-section-content .quotation-form form .error-message{
    z-index: 999;
    position: absolute;
    font-size: 12px;
    font-weight: bold;
    padding-left: 25px;
    margin-top: -3px;
}


.quotation .quotation-section-content .quotation-form form .error{
    border:1px solid red!important;
    outline-color: red;
}

.quotation .quotation-section-content .quotation-form form .success{
    border:1px solid green!important;
    outline-color: green;
}


/* inputs & textarea */
.quotation .quotation-section-content .quotation-form form textarea{
    height: 130px;
    padding-top: 20px;
}

.quotation .quotation-section-content .quotation-form form textarea[name="bulletBusinessModel"]{
    height: 180px;
}

.quotation .quotation-section-content .quotation-form form textarea[name="bulletProductMargin"]{
    height: 110px;
}

.quotation .quotation-section-content .quotation-form form .input-info{
    height: 55px;
    padding-top: 15px;
}

/* display del Label & placeholder */
.quotation .quotation-section-content .quotation-form form fieldset{
    position:relative;
    margin-bottom: 18px;
    padding-left: 0;
}


.quotation .quotation-section-content .quotation-form form fieldset .label-element{
    position:absolute;
    top:5px;
    left:5px;
    font-size:12px;
    color:#666;
    padding-left: 25px;
    font-weight: bold;
}

.quotation .quotation-section-content .quotation-form form .label-check{
    position:absolute;
    top:0px;
    padding-left: 8px;
}

.quotation .quotation-section-content .quotation-form form .remove-col{
    display: grid;
    align-items: center;
    justify-content: center;
}

.icon{
    margin-left: 10px;
}

/* Checkbox */
.quotation .quotation-section-content .quotation-form form input[type=checkbox] {
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    border: 1px solid #8d8d8d;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.quotation .quotation-section-content .quotation-form form input[type=checkbox]:checked{
    background-color: #4b9c4e;
}

/* posición del botón submit */
.quotation .quotation-section-content .quotation-form form .submit-botton{
    text-align: center;
    padding-top: 5px;
}

form .g-action-button:disabled,
form .g-action-button[disabled]{
    color:#fff!important;
}

h2.quotation_page_subtitle {
    color: #00061d;
    margin-top: 0;
    margin-bottom: 1.2rem;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: inherit;
}

.div_img {
    display: inline-block;
    margin: 0 12px;
}

.quotation_paragraphs {
    padding: 0 150px;
}

.carousel .carousel-indicators button {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: #fff;
    border: 1px solid #357fd8;
}

/* Cards */
.quotation-section-cards .card {
    width: 100%;
    border: 0;
    text-align: center;
    border-radius: 1rem;
    display: grid;
    align-content: center;
    height: 250px;
}

@media only screen and (max-width: 640px) {
    .quotation-section-cards .card {
        height: 295px;
    }

    .quotation_paragraphs {
        padding: 0 7px;
    }
}

@media only screen and (min-width: 640px) {
    .quotation-section-cards .card {
        height: 230px;
    }

    .quotation_paragraphs {
        padding: 0 7px;
    }
}

/* md */
@media only screen and (min-width: 768px) {
    .quotation-section-cards .card {
        height: 450px;
    }
}

@media only screen and (min-width: 992px) {
    .quotation-section-cards .card {
        height: 350px;
    }

    .quotation_paragraphs {
        padding: 0 30px;
    }
}

@media only screen and (min-width: 1280px) {
    .quotation-section-cards .card {
        height: 300px;
    }

    .quotation_paragraphs {
        padding: 0 50px;
    }
}

@media only screen and (min-width: 1400px) {
    .quotation_paragraphs {
        padding: 0 120px;
    }
}
