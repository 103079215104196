@import url('../../../node_modules/bootstrap-icons/font/bootstrap-icons.css');


.business-detail .business-detail-section-content .business-info .breadcrumbs .desktop p{
    font-weight: bold;
    color: #357fd8;
}

.business-detail .business-detail-section-content .business-info .breadcrumbs .desktop .arrow{
    color: #8fc032;
    font-weight: bolder;
}

.business-detail .business-detail-section-content .business-info .breadcrumbs .desktop .link{
    cursor: pointer;
}

.business-detail .business-detail-section-content .business-info .breadcrumbs .back button{
    font-weight: bolder!important;
    all: unset;
    cursor: pointer;
}



.business-detail .business-detail-section-content .contact{
    background-color: #edebeb;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.gradient-bottom{
    background: linear-gradient(to right, #357fd8, #8fc032);
    height: 10px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}


.business-detail .business-detail-section-content .contact h2 {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
    color: #357fd8;
    font-weight: 700;
    line-height: 2rem;
    text-align: center;
}

.business-detail .business-detail-section-content .contact p {
    text-align: center;
}

/* Form inputs*/
.business-detail .business-detail-section-content .contact form .input-info,
.business-detail .business-detail-section-content .contact form textarea{
    width: 100%;
}

/* inputs & textarea */
.business-detail .business-detail-section-content .contact form textarea{
    height: 200px;
    padding-top: 20px;
}

.business-detail .business-detail-section-content .contact form .input-info{
    height: 55px;
    padding-top: 15px;
}

/* display del Label & placeholder */
.business-detail .business-detail-section-content .contact form fieldset{
    position:relative;
    margin-bottom: 18px;
}

.business-detail .business-detail-section-content .contact form .input-info,
.business-detail .business-detail-section-content .contact form textarea{
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    border: 1px solid #8d8d8d;
    padding-left: 25px;
}

.business-detail .business-detail-section-content .contact form fieldset .label-element{
    position:absolute;
    top:5px;
    left:5px;
    font-size:12px;
    color:#666;
    padding-left: 25px;
    font-weight: bold;
}

.business-detail .business-detail-section-content .contact form .label-check{
    position:absolute;
    top:0px;
    padding-left: 8px;
}

/* Checkbox */
.business-detail .business-detail-section-content .contact form input[type=checkbox] {
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    border: 1px solid #8d8d8d;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.business-detail .business-detail-section-content .contact form input[type=checkbox]:checked{
    background-color: #4b9c4e;
}

/* posición del botón submit */
.business-detail .business-detail-section-content .contact form .button-right{
    text-align: right;
   }

h2.business_page_subtitle {
    /*font-size: 1.55rem;
    margin-bottom: 1rem;*/
    color: #00061d !important;
    margin-top: 0;
    margin-bottom: 1.2rem;
    font-weight: 400 !important;
    font-size: 1.2rem !important;
    line-height: inherit;
}

@media only screen and (max-width: 767px) {
    .business-detail .business-detail-section-content .contact form .button-right{
        text-align: center;
    }
}

/* validation */


.business-detail .business-detail-section-content .contact form .error-message{
    z-index: 999;
    position: absolute;
    font-size: 12px;
    font-weight: bold;
    padding-left: 25px;
    margin-top: -3px;
}

.business-detail .business-detail-section-content .contact form .error{
    border:1px solid red!important;
    outline-color: red;
}

.business-detail .business-detail-section-content .contact form .success{
    border:1px solid green!important;
    outline-color: green;
}

/* effect */
/* Background effect */
.business-detail .business-detail-section-content .back-effect{
    width:100%;
    height:280px;
    background-position:  center left;
    background-image: url(../../assets/images/contact-back.png);
    background-repeat: no-repeat;
    background-size: cover;
    bottom:0;
    margin-top: 50px;
    margin-bottom: -160px;
}

/* md */
@media only screen and (min-width: 768px) {
    .business-detail .business-detail-section-content .back-effect{
        height:260px;
        margin-bottom: -90px;
        margin-top:50px;
    }
}
/* l */
@media only screen and (min-width: 992px) {
    .business-detail .business-detail-section-content .back-effect{
        height:260px;
        margin-bottom: -90px;
        margin-top:50px;
    }
}
/* xl */
@media only screen and (min-width: 1200px) {
    .business-detail .business-detail-section-content .back-effect{
        height:330px;
        margin-bottom: -110px;
        margin-top:50px;
    }
}

/* xxl */
@media only screen and (min-width: 1500px) {
    .business-detail .business-detail-section-content .back-effect{
        height:390px;
        margin-bottom: -150px;
        margin-top:50px;
    }
}

/* Business Details inof */
/* State tag */
.business-detail .business-detail-section-content .business-info .state{
    width: 200px;
    border-radius: 15px;
    text-align: center;
    color: #fff;
    font-weight: bold;
    margin-bottom: 1rem;
    padding: 5px;
}

.visit{
    color: #357fd8!important;
    border: 3px solid #357fd8!important;
    margin-left: 8px!important;
}

.business-detail .business-detail-section-content .business-info .state-reserved{
    background-color: #b22222;
}

.business-detail .business-detail-section-content .business-info .state-available{
    background-color: #228b22;
}

.business-detail .business-detail-section-content .business-info .state-featured{
    background-color: #ffff00;
}

.business-detail .business-detail-section-content .business-info .icons h6{
    padding-top: 5px;
    color: #05264d;
    font-weight: bold;
}

/* interested button */

.business-detail .business-detail-section-content .business-info .button{
    padding-top: 16px;
    padding-bottom: 16px;
}

.business-detail .business-detail-section-content .business-info .button button{
   width: 100%;
}

.business-detail .business-detail-section-content .business-info .button .line{
    display:none;
 }

@media only screen and (min-width: 768px) {
    .business-detail .business-detail-section-content .business-info .button .line{
        height: 2px;
        background: #357fd8;
        display:inline-block;
        width: 100%;
    }

    .business-detail .business-detail-section-content .business-info .button button{
        margin-left: 0;
        display:inline-block;
        width: 300px;
    }
    .business-detail .business-detail-section-content .business-info .button{
        display: flex;
        align-items: center;
       }
}

/* Bulletpoints */
.business-detail .business-detail-section-content .business-info .bulletpoints p{
    color: #4b5563;
    font-size: 1rem;
}

.business-detail .business-detail-section-content .business-info .bulletpoints ul{
    list-style: none;
    padding: 0;
}

.business-detail .business-detail-section-content .business-info .bulletpoints ul li {
    font-weight: bold;
    color: #05264d;
    }



.business-detail .business-detail-section-content .business-info .bulletpoints ul li .custom-bullet{
    background: #8fc032;
    border-radius: 50%;
    display: inline-block;
    margin-right: 6px;
    height: 12px;
    width: 12px;
}

.business-detail .business-detail-section-content .business-info .social-networks-icons{
    margin-bottom: 16px;
    text-align: center;
}

.business-detail .business-detail-section-content .business-info .social-networks-icons img{
    margin-right: 10px;
 }

@media only screen and (min-width: 768px) {
    .business-detail .business-detail-section-content .business-info .social-networks-icons{
        text-align: left;
    }
}

.business-detail .business-detail-section-content .business-info .extra-features{
    margin-bottom: 16px;
}

.business-detail .business-detail-section-content .business-info .extra-features img{
    width: 18px;
    margin-right: 3px;
}

.business-detail .business-detail-section-content .business-info .extra-features span{
    font-weight: 600;
    color: #05264d;
    margin-right: 15px;
}

.business-contact-white-bg{
    background-color: #fff;
}

