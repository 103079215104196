.blog .blog-section-content h3{
    color: #05264D;
    font-weight: bold;
}

/* Banner */
.blog .blog-section-content .banner{
    height: 300px;
    width: 100%;
    background-image: url('../../assets/images/blog_banner.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.blog .blog-section-content .banner .section-title {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
}

h1{
    margin-top: 1.7rem;
    margin-bottom: 1.7rem;
    color: #357fd8;
    font-weight: bold;
    font-weight: 700;
    font-size: 2.7rem;
}

/* xs banner mobile*/
@media only screen and (max-width: 576px) {
    .blog .blog-section-content .banner{
        height: 300px;
        width: 100%;
        background-image: url('../../assets/images/blog_banner_mobile.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .blog .blog-section-content .banner .section-title {
        padding-left: 5%;
    }

    .blog .blog-section-content .banner .section-title h6{
        font-size: 0.8rem;
    }

    h1{
        font-size: 1.8rem;
    }
}

/* Searchbar */
.blog .blog-section-content .search .search-form-igroup{
   border-radius: 2.5rem;
   }

/* Carrousel */


/* item */
.blog .blog-section-content .desktop-carrousel .item{
    border-radius: 25px;
    box-shadow: 10px 3px 15px lightslategray;
}

/* Cards desktop*/
.blog .blog-section-content .desktop-carrousel .card{
    border: 0;
    text-align: center;
    display: grid;
    align-items: center;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
     }

.blog-section-content .desktop-carrousel .card .logo-image{
    max-width: 50%;
}

.blog .blog-section-content .desktop-carrousel .carrousel-img img{
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    height: 400px;
}

.blog .blog-section-content .desktop-carrousel .card .card-body .card-title{
    color: #05264D;
    font-weight: bold;
}

.blog .blog-section-content .desktop-carrousel .card .card-body .card-date{
    color: #357FD8;
}


/* Cards mobile*/
.blog-section-content .mobile-carrousel .card{
    width: 100%;
    border: 0;
    text-align: center;
    border-radius: 4%;
    height: 530px;
}

.blog-section-content .mobile-carrousel .card .logo-image{
    max-width: 80%;
}

.blog-section-content .mobile-carrousel .card .card-img-top{
    height: 230px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-top-left-radius: 4%;
    border-top-right-radius: 4%;
}

.blog-section-content .mobile-carrousel .card .card-body .card-title{
    color: #05264D;
    font-weight: bold;
}

.blog-section-content .mobile-carrousel .card .card-body .card-date{
    color: #357FD8;
}

.blog .blog-section-content .mobile-carrousel .card{
    border: 0;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
}

.blog .blog-section-content .card a,
.blog .blog-section-content .card a:link,
.blog .blog-section-content .card a:visited,
.blog .blog-section-content .card a:hover,
.blog .blog-section-content .card a:active{
    text-decoration: none;
    color: #000000;
}

.blog .blog-section-content .result-list .card{
    height: 530px;
}

.blog .blog-section-content .result-list .card .card-body {
    display: grid;
    align-items: center;
    height: 300px;
}



/* Filters */

.blog .blog-section-content .filters p{
    font-weight: bold;
    color: #05264d;
}


/* form */

.blog .blog-section-content .filters-form{
    background-color: #f5f5f5;
    border-radius: 3%;
    margin-bottom: 2rem;
}

.blog .blog-section-content .filters-form h4{
    color: #00061d;
    font-weight: bold;
}

/* Form inputs*/
.blog .blog-section-content .filters-form form .input-info{
    width: 100%;
    outline-color: #4b9c4e;
}

/* inputs & textarea */
.blog .blog-section-content .filters-form form select{
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #fff;
    background-image: url("../../assets/icons/downArrow.svg")!important;
    background-repeat: no-repeat;
    background-position-x: 90%;
    background-position-y: 50%;
}

.blog .blog-section-content .filters-form form .input-info{
    height: 55px;
}

.blog .blog-section-content .filters-form form .input-info{
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    border: 1px solid #8d8d8d;
    padding-left: 25px;
}

.blog .blog-section-content .filters-form .reset-filters button:disabled{
    color: #6c757d;
}


