
/*  Banner */

.aboutUs .aboutUs-section-content .banner{
    height: 550px;
    width: 100%;
    /* Pendiente modificar imagen */
    background-image: url('../../assets/images/aboutUsBanner.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

h2.aboutus_page_subtitle {
    color: #00061d !important;
    margin-top: 0;
    margin-bottom: 1.2rem;
    font-weight: 400 !important;
    font-size: 1.1rem !important;
    line-height: inherit;
}

/* xs banner mobile*/
@media only screen and (max-width: 576px) {
    .aboutUs .aboutUs-section-content .banner{
         width: 100%;
        /* Pendiente modificar imagen */
        background-image: url('../../assets/images/aboutUsBannerMB.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

}

.aboutUs .aboutUs-section-content .banner .section-title{
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    padding-left: 5%;
}

/* Misión y visión */

.aboutUs .aboutUs-section-content .mision{
    padding-top: 8%;
}

.aboutUs .aboutUs-section-content .mision .image{
   align-self: center;
}

/* md */
.aboutUs .aboutUs-section-content .mision .text-content{
    padding: 5% 10% 5% 10%;
}

/* sm */
@media only screen and (max-width: 970px) {
    .aboutUs .aboutUs-section-content .mision .text-content{
        padding: 4% 7% 4% 7%;
    }
}

/* xs */
@media only screen and (max-width: 760px) {
    .aboutUs .aboutUs-section-content .mision .text-content{
        padding: 4% 12% 4% 12%;
    }
}

.aboutUs .aboutUs-section-content .mision .text-content h6{
    color: #91c131;
    letter-spacing: 10px;
    line-height: 1.7rem;
    font-size: 1rem;
}

.aboutUs .aboutUs-section-content .news-carousel{
    background-color: #05264d;
    padding-right: 10%;
    padding-left: 10%;
}


/* Equipo */

.aboutUs .aboutUs-section-content .team .button button{
    width: 220px;
}

.aboutUs .aboutUs-section-content .team .section-title p{
    padding-left:15%;
    padding-right:15%;
    margin-bottom: 40px;
}

.aboutUs .aboutUs-section-content .team{
    display: grid;
    justify-items: center;
}

.aboutUs .aboutUs-section-content .team .team-grid .team-member{
    box-shadow: 0px 10px 10px 0px rgba(170,170,170,0.75);
    border-radius: 15px;
    padding: 10px 10px 10px 25px;
    margin-bottom: 50px;
    height: 285px;
}

.aboutUs .aboutUs-section-content .team .team-grid .height-auto{
    height: auto;
    padding-right: 25px;
}


.aboutUs .aboutUs-section-content .team .team-grid .team-member h5{
    color: #05264D;
    font-weight: bold;
}

.aboutUs .aboutUs-section-content .team .team-grid .team-member .role{
    font-weight: 500;
}

.aboutUs .aboutUs-section-content .team .team-grid .team-member .member-info{
    color: #4b5563;
}

.aboutUs .aboutUs-section-content .team .team-grid .team-member img{
    max-width: 112px;
    margin-bottom: 15px;
}

/* xs */
@media only screen and (max-width: 576px) {
    .aboutUs .aboutUs-section-content .team .team-grid .col-12{
        padding: 0 20% 0 20%;
    }

    .aboutUs .aboutUs-section-content .team .section-title p{
        padding-left:10%;
        padding-right:10%;
    }
}

/* xxs */
@media only screen and (max-width: 460px) {
    .aboutUs .aboutUs-section-content .team .team-grid .col-12{
        padding: 0 10% 0 10%;
    }
}

/* Logos carosuel */
.aboutUs .aboutUs-section-content .logo-carousel .carousel-control-next-icon,
.aboutUs .aboutUs-section-content .logo-carousel .carousel-control-prev-icon,
.aboutUs .aboutUs-section-content .logo-carousel .carousel-indicators{
  display: none;
}

.aboutUs .aboutUs-section-content .logo-carousel .carousel .carousel-inner .carousel-item img{
    width: 100%;
    max-width: 200px;
}

html {
    scroll-behavior: smooth;
}






