
.dashboard .dashboard-content-section .top-buttons a{
    padding: 0.6rem;
    overflow: auto;
    white-space: nowrap;
}

.dashboard .dashboard-content-section .top-buttons .sell{
    color: #fff;
    /* fallback for old browsers */
    background: #4cbded;

    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to bottom, rgba(76, 189, 237, 1), rgba(52, 128, 216, 1));

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to bottom, rgba(76, 189, 237, 1), rgba(52, 128, 216, 1))
}

.dashboard .dashboard-content-section .image{
    display: grid;
   align-items: flex-end;
}

.dashboard .dashboard-content-section button{
    background-color: transparent;
    margin-right: 2px;
    border: 1px solid gray;
    border-radius: 10px;
}

.dashboard .dashboard-content-section .spinner-border{
    height: 17px;
    width: 17px;
}

.dashboard .dashboard-content-section .top-buttons .btn-outline-primary {
    color: #0d6efd;
    border-color: #0d6efd;
}

.dashboard .dashboard-content-section .top-buttons .sell {
    color: #fff;
    border-color: #0d6efd;
}

.dashboard .dashboard-content-section .top-buttons .btn-outline-primary:hover {
    color: #fff;
    background-color: #0d6efd;
}


/* Estilo custom del acordión */
.dashboard .dashboard-content-section .accordion-businesses .accordion-button:not(.collapsed){
    background-color: #fff;
    border: none;
    box-shadow: none;
}

.dashboard .dashboard-content-section .accordion-businesses .accordion-button:focus {
    border: none;
    box-shadow: none;
}

.dashboard .dashboard-content-section .accordion-businesses .accordion-item,
.dashboard .dashboard-content-section .accordion-businesses .collapsed,
.dashboard .dashboard-content-section .accordion-businesses .accordion-button,
.dashboard .dashboard-content-section .accordion-businesses .accordion-item:first-of-type,
.dashboard .dashboard-content-section .accordion-businesses .accordion-item:last-of-type .accordion-button.collapsed
{
    border-radius: 25px!important;

}

.dashboard .dashboard-content-section .accordion-businesses .accordion-item{
    box-shadow: 2px 2px 7px #a0aec0 ;
}

.dashboard .dashboard-content-section .accordion-businesses .accordion-button{
    color: #05264d!important;
    font-weight: bolder;
    letter-spacing: 0.1rem;
    font-size: 1rem;
}

.dashboard .dashboard-content-section .accordion-businesses .accordion-body{
    margin-bottom: 2%;
    padding-top: 0!important;
}

.dashboard .dashboard-content-section .accordion-businesses .accordion-body .info{
    font-size: 1rem;
}

/* iconos flechitas del acordión  */

.accordion-button::after {
    background-image: url("../../../assets/icons/downArrow.svg")!important;
    transform: rotate(-90deg);
}

.faqs .faqs-section-content .accordion-button:not(.collapsed)::after {
    transform: rotate(1deg);
}
