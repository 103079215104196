@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

.form-control:focus {
    box-shadow:none;
 }
.btn:focus{
    box-shadow:none;
}

.search {
    position: absolute;
    -webkit-transform: translate(0%,-50%);
    transform: translate(0%,-50%);
    width: 100%;
  }

.search-form {
    background-color: #05264d;
    border-radius: 2.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.search-form-igroup {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    background-color: rgb(255, 255, 255);
    border: 0px;
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
    height: 3.2rem;
    overflow: hidden;
    padding-left: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.search-form-igroup-icon {
    width: 1.4rem;
    height: 1.4rem;
    margin-right: 1vw;
}
.search-form-igroup-input {
    border: 0px;
    font-weight: 500;
    color: #05264d;
    outline: none;
}
.search-dropdown {
    display: flex;
    flex-direction: row;
    background-color: rgb(255, 255, 255);
    height: 3.2rem;
    border-radius: 1.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.search-button:disabled {
    color: #fff;
}
.search-button {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    color: #fff;
    background: #8fc032;
    border: 0px;
    height: 3.2rem;
    width: 100%;
    justify-content: center;
    align-items: center;
    background: -webkit-linear-gradient(to bottom, #8fc032, #147f66);
    background: linear-gradient(to bottom, #8fc032, #147f66)
}

.search-dropdown select{
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #fff;
  background-image: url("../assets/icons/dropdown-arrow.svg")!important;
  background-repeat: no-repeat;
  background-position-x: 90%;
  background-position-y: 50%;
  border: transparent;
  width: 100%;
  padding-left: 1.2rem;
  outline: none;
  border-radius: 1.5rem;
}

@include media-breakpoint-down(md) {
    .search {
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }
    .search-form {
        border-radius: 2rem;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
    }
    .search-form-igroup {
        border-top-right-radius: 1.5rem;
        border-bottom-right-radius: 1.5rem;
    }
    .search-form-igroup-icon {
        width: 1.2rem;
        height: 1.2rem;
    }

    .search-form-igroup > input{
        font-size: 0.9rem;
    }

}
@include media-breakpoint-up(md) {
    .search-form > div:first-of-type {
        padding-right: 0px;
    }
    .search-form-dropdown {
        padding-left: 0px;
    }
    .search-dropdown {
        border-width: 0;
        border-left-width: 2px;
        border-color: rgb(206, 206, 206);
        border-style: solid;
    }

  .search-dropdown,
  .search-dropdown select{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

}