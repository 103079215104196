.footer .footer-section-content{
    background-color: #00061d;
    color: #fff;
 }

/* linea divioria */
.footer .footer-section-content hr{
    color: #4ec0ed;
    height: 2px;
    margin-top: 4rem;
    margin-bottom: 3rem;
}

.footer .footer-section-content .top-footer h4{
    color: #4ec0ed;
    margin-bottom: 1.7rem;
}

/* listas de links */
.footer .footer-section-content .top-footer .links{
    padding-top: 6rem;
}

.footer .footer-section-content a{
    text-decoration: none;
    color: inherit;
}

.footer .footer-section-content .top-footer ul{
    list-style: none;
    padding-left: 0;
}

.footer .footer-section-content .top-footer li{
    margin-bottom: 1rem;
}

.testimonial-link:hover{
    cursor: pointer;
}



/* redes sociales */
.footer .footer-section-content .redes{
    text-align: center;
    margin-bottom: 1rem;
}

.footer .footer-section-content .redes a{
    display: inline-block;
    position: relative;
    z-index: 1;
}

.footer .footer-section-content .redes span{
    display: inline-block;
}

.footer .footer-section-content .redes object{
    position: relative;
    z-index: -1
}

@media (min-width: 768px) {
    .footer .footer-section-content .redes{
        text-align: right;
        margin-bottom: 0;
    }
}
.footer .footer-section-content .redes object, .footer .footer-section-content .redes span, .footer .footer-section-content .redes a{
    width: 36px;
    height: 36px;
    margin-right: 10px;
    border-radius: 20%;
}

