.lang-selector{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
}

.lang-selector .lang-selector-content{
    height: 50px;
    padding: 10px;
    background: #000;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: stretch;
}

.lang-selector .lang-selector-content .flex{
    display: flex;
    flex-direction: row;
    align-items: stretch;
}

.lang-selector .lang-selector-content .selector {
    padding-left: 50px;
}

.lang-selector .lang-selector-content .selector button{
    background-color: transparent;
    color: #fff;
    border: 0!important;
}

@media only screen and (max-width: 992px) {
    .lang-selector .lang-selector-content .text{
        display: none;
    }
    }

@media only screen and (max-width: 576px) {
    .lang-selector .lang-selector-content .phone{
        display: none;
    }
    .lang-selector .lang-selector-content .selector{
        padding-left: 0;
    }
}

.lang-selector .lang-selector-content .selector .selected{
    color: #2c6bb6;
    font-weight: bold;
}

.lang-selector .lang-selector-content .selector a,
.lang-selector .lang-selector-content .selector a:link,
.lang-selector .lang-selector-content .selector a:visited,
.lang-selector .lang-selector-content .selector a:hover,
.lang-selector .lang-selector-content .selector a:active{
    text-decoration: none;
    color: #fff;
}

