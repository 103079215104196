/* Breadcrums */
.blog-detail .breadcrumbs .arrow{
    color: #4b9c4e;
    font-weight: bolder;
}

.blog-detail .breadcrumbs .link{
    cursor: pointer;
    font-weight: bolder;
}

/* Banner */
.blog-detail .banner{
    background-image: url("../../../assets/images/blogBackgroundMobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 300px;
    display: grid;
    align-items: center;
    background-position: center;
}

@media only screen and (min-width: 768px) {
    .blog-detail .banner {
        background-image: url("../../../assets/images/blogDetail.jpg");
    }
}

.blog-detail .banner .text{
    width: 75%;
}

.blog-detail .banner .text h1,
.blog-detail .banner .text h6{
    color: #fff;
}

/* Body */
.blog-detail .blog-body{
    display: grid;
    justify-items: center;
}

.blog-detail .blog-body ul,
.blog-detail .blog-body ol{
    margin-left: 6%;
}

.blog-detail .blog-body h2{
    text-align: center;
    color: #000;
    font-size: 1.4rem;
    line-height: 1.7rem;
    margin-top: 0rem;
    margin-bottom: 0.5rem;
}

.blog-detail .blog-body a{
    display: block;
    text-align: center;
}

.blog-detail .blog-body .image{
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
}

.blog-detail .blog-body img{
    border-radius: 25px;
    width: 100%;
}

@media only screen and (min-width: 768px) {
    .blog-detail .blog-body img{
      width: 60%;
    }
    .blog-detail .blog-body p{
        padding-left: 6%;
        padding-right: 6%;
    }

    .blog-detail .blog-body h2{
        font-size: 1.65rem;
    }
}

.blog-detail hr{
    border: 3px solid #357FD8;
    margin-top: 40px;
    margin-bottom: 40px;
}