/* Carousel indicators*/
.carousel-indicators {
    position: relative;
}

/*  Banner */

.testimonials-section-content .banner {
    height: 700px;
    width: 100%;
    background-image: url('../../assets/images/testimonials/testimonialsHeaderMobile.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.buttons-select {
    padding-bottom: 0 !important;
}

.white {
    background: #ffffff !important;
    padding-top: 0 !important;
}

.section-title.testimonial-custom-title h2 {
    color: white !important;
    font-size: 2.7rem;
}

h2.testimonials_page_subtitle {
    color: #00061d !important;
    margin-top: 0;
    margin-bottom: 1.2rem;
    font-weight: 400 !important;
    font-size: 1.1rem !important;
    line-height: inherit;
}

/* md */
@media only screen and (min-width: 768px) {
    .testimonials-section-content .banner {
        height: 550px;
    }

    .testimonials-section-content .banner {
        background-image: url('../../assets/images/testimonials/testimonialsHeader.png');
    }
}

.testimonials-section-content .banner .section-title {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
}

.carousel .carousel-indicators button {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: #fff;
    border: 1px solid #357fd8;
}
.carousel .carousel-indicators .active {
    background-color: #357fd8;
}

/* Carousel controls*/
.carousel {
    margin: 1rem;
}
.carousel-inner {
    height: auto;
}

.carousel-control-next-icon {
    background-image: url('../../assets/icons/rightArrow.svg') !important;
}

.carousel-control-prev-icon {
    background-image: url('../../assets/icons/leftArrow.svg') !important;
}

/* xs */
@media only screen and (max-width: 576px) {
    .carousel-control-next-icon {
        margin-right: -100px;
    }
    .carousel-control-prev-icon {
        margin-left: -100px;
    }
    .section-title.testimonial-custom-title h2 {
        font-size: 1.9rem;
    }
}
/* s */
@media only screen and (min-width: 576px) {
    .carousel-control-next-icon {
        margin-right: -120px;
    }
    .carousel-control-prev-icon {
        margin-left: -120px;
    }
    .section-title.testimonial-custom-title h2 {
        font-size: 1.9rem;
    }
}
/* md */
@media only screen and (min-width: 768px) {
    .carousel-control-next-icon {
        margin-right: -150px;
    }
    .carousel-control-prev-icon {
        margin-left: -150px;
    }
    .section-title.testimonial-custom-title h2 {
        font-size: 1.9rem;
    }
}
/* l */
@media only screen and (min-width: 992px) {
    .carousel-control-next-icon {
        margin-right: -190px;
    }
    .carousel-control-prev-icon {
        margin-left: -190px;
    }
    .section-title.testimonial-custom-title h2 {
        color: white !important;
        font-size: 2.7rem;
    }
}
/* xl */
@media only screen and (min-width: 1200px) {
    .carousel-control-next-icon {
        margin-right: -200px;
    }
    .carousel-control-prev-icon {
        margin-left: -200px;
    }
    .section-title.testimonial-custom-title h2 {
        color: white !important;
        font-size: 2.7rem;
    }
}

/* xxl */
@media only screen and (min-width: 1500px) {
    .carousel-control-next-icon {
        margin-right: -240px;
    }
    .carousel-control-prev-icon {
        margin-left: -240px;
    }
    .section-title.testimonial-custom-title h2 {
        color: white !important;
        font-size: 2.7rem;
    }
}

.testimonials-section .testimonials-section-content {
    background-color: #05264d;
}
.testimonial-avatar {
    width: 5rem !important;
    height: 5rem;
    background-color: #91c131;
}

/* Cards */
.testimonials-section .card {
    width: 100%;
    border: 0;
    text-align: center;
    border-radius: 1rem;
    display: grid;
    align-content: center;
    height: 200px;
}

@media only screen and (max-width: 640px) {
    .testimonials-section .card {
        height: 350px;
    }
}

@media only screen and (min-width: 640px) {
    .testimonials-section .card {
        height: 230px;
    }
}

/* md */
@media only screen and (min-width: 768px) {
    .testimonials-section .card {
        height: 500px;
    }
}

@media only screen and (min-width: 992px) {
    .testimonials-section .card {
        height: 350px;
    }
}

@media only screen and (min-width: 1280px) {
    .testimonials-section .card {
        height: 280px;
    }
}

.testimonials-section .card .card-img-top {
    height: 230px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-top-left-radius: 4%;
    border-top-right-radius: 4%;
}

.testimonials-section .card .card-body .card-title {
    color: #05264d;
    font-weight: bold;
    text-align: center;
}

.testimonials-section .card .card-body .card-date {
    color: #357fd8;
}
