/* Banner */

.faqs .faqs-section-content .banner {
    height: 550px;
    width: 100%;
    background-image: url('../../assets/images/faqs.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.faqs .faqs-section-content .banner .section-title {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    padding-left: 5%;
}

h2.faqs_page_subtitle {
    color: #00061d !important;
    margin-top: 0;
    margin-bottom: 1.2rem;
    font-weight: 400 !important;
    font-size: 0.8rem !important;
    line-height: inherit;
}

@media only screen and (min-width: 577px) {
    h2.faqs_page_subtitle {
        font-size: 1.1rem !important;
    }
}

/* xs banner mobile*/
@media only screen and (max-width: 576px) {
    .faqs .faqs-section-content .banner {
        height: 300px;
        width: 100%;
        background-image: url('../../assets/images/faqs.png');
        background-size: cover;
        background-position: 40%;
        background-repeat: no-repeat;
    }

    .faqs .faqs-section-content .banner .section-title h6,
    .faqs .faqs-section-content .banner .section-title p {
        font-size: 0.8rem;
    }

    /*h2.faqs_page_subtitle {
        font-size: 0.8rem !important;
    }*/
}

/* xxs banner mobile*/
@media only screen and (max-width: 460px) {
    .faqs .faqs-section-content .banner {
        height: 300px;
        width: 100%;
        background-image: url('../../assets/images/faqs.png');
        background-size: cover;
        background-position: 45%;
        background-repeat: no-repeat;
    }

    .faqs .faqs-section-content .banner .section-title h6,
    .faqs .faqs-section-content .banner .section-title p {
        font-size: 0.8rem;
    }

    /*h2.faqs_page_subtitle {
        font-size: 0.8rem !important;
    }*/
}

/* Botones de selección comprador/vendedor */
.faqs .faqs-section-content .buttons-select {
    padding-top: 5%;
    padding-bottom: 5%;
}

.faqs .faqs-section-content .buttons-select .seller {
    text-align: right;
}

.faqs .faqs-section-content .buttons-select .buyer {
    text-align: left;
}

@media only screen and (min-width: 766px) {
    .faqs .faqs-section-content .buttons-select button {
        width: 60%;
    }

    h2.faqs_page_subtitle {
        font-size: 1.4rem !important;
    }
}

@media only screen and (max-width: 766px) {
    .faqs .faqs-section-content .buttons-select button {
        width: 100%;
    }
}

@media only screen and (min-width: 766px) and (max-width: 1400px) {
    .faqs .faqs-section-content .buttons-select button {
        height: 70px !important;
    }
}

/* unselected button */
.faqs .faqs-section-content .buttons-select button {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border: 2px solid #17a372;
    color: #17a372;
    height: 3.2rem;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.faqs .faqs-section-content .buttons-select button:hover {
    background-color: #fff;
}

/* Selected button */
.faqs .faqs-section-content .buttons-select .selected {
    color: #fff;
    background: #8fc032;
    background: -webkit-linear-gradient(to bottom, #8fc032, #147f66);
    background: linear-gradient(to bottom, #8fc032, #147f66);
}

/* Estilo custom del acordión */
.faqs
    .faqs-section-content
    .accordion-questions
    .accordion-button:not(.collapsed) {
    background-color: #fff;
    border: none;
    box-shadow: none;
}

.faqs .faqs-section-content .accordion-questions .accordion-button:focus {
    border: none;
    box-shadow: none;
}

.faqs .faqs-section-content .accordion-questions .accordion-item,
.faqs .faqs-section-content .accordion-questions .collapsed,
.faqs .faqs-section-content .accordion-questions .accordion-button,
.faqs .faqs-section-content .accordion-questions .accordion-item:first-of-type,
.faqs
    .faqs-section-content
    .accordion-questions
    .accordion-item:last-of-type
    .accordion-button.collapsed {
    border-radius: 25px !important;
}

.faqs .faqs-section-content .accordion-questions .accordion-item {
    box-shadow: 2px 2px 7px #a0aec0;
}

.faqs .faqs-section-content .accordion-questions .accordion-button {
    color: #05264d !important;
    font-weight: bolder;
    letter-spacing: 0.1rem;
    font-size: 1rem;
}

.faqs .faqs-section-content .accordion-questions .accordion-body {
    margin-bottom: 2%;
}

/* iconos flechitas del acordión  */

.accordion-button::after {
    background-image: url('../../assets/icons/downArrow.svg') !important;
    transform: rotate(-90deg);
}

.faqs .faqs-section-content .accordion-button:not(.collapsed)::after {
    transform: rotate(1deg);
}

.whitespace {
    white-space: pre-wrap;
}

/* Efecto lineas de fondo */

.faqs .faqs-section-content .back-effect {
    width: 100%;
    height: 200px;
    background-position: center center;
    background-image: url(../../assets/images/faq-back.png);
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    z-index: -1;
    position: relative;
}

@media only screen and (max-width: 766px) {
    .faqs .faqs-section-content .back-effect {
        margin-top: -100px;
    }
}
