.header-navbar {
    padding-top: 50px;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
}
.header-navbar-nav ul{
    margin-right: 1.5rem;
}
.header-navbar-nav li{
    padding: 0;
}

.header-navbar-nav-actions a{
    padding: 0.6rem;
    overflow: auto;
    white-space: nowrap;
}
.header-navbar-nav a{
    overflow: auto;
    white-space: nowrap;
   }
.header-navbar-nav-actions > :first-child{
    color: #fff;
    /* fallback for old browsers */
    background: #4cbded;

    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to bottom, rgba(76, 189, 237, 1), rgba(52, 128, 216, 1));

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to bottom, rgba(76, 189, 237, 1), rgba(52, 128, 216, 1))
}

/* Corrige alineación de textos de botones al transformarlos en Links */
.fix-button-link{
    display: grid;
    align-items: center;
}

.header-navbar-nav-actions{
    display: flex;
}

/* xs */
@media only screen and (max-width: 380px) {
    .header-navbar-nav-actions{
        display: block;
        text-align: center;
    }

    .header-navbar-nav-actions > :first-child{
        margin-bottom: 8px;
    }

}

@media only screen and (min-width: 990px) {
    .header-navbar-nav li{
        padding: 1rem;
    }

}