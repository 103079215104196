@import url('../../../node_modules/bootstrap-icons/font/bootstrap-icons.css');

.user .user-content-section .logo{
    text-align: center;
}

/* Background effect */
.user .user-content-section .back-effect{
    width:100%;
    height:130px;
    background-position: left top;
    background-image: url('../../assets/images/contact-back.png');
    background-repeat: no-repeat;
    background-size: cover;
    bottom:0;
    position: absolute;
    z-index: -1;
}



.user .user-content-section{
    margin-top: 60px;
    height: 100vh;
}

/*.dashboard .dashboard-content-section .login-form form .submit-botton,*/
.user .user-content-section .login-form form .submit-botton{
    display: grid;
    justify-items: center;
    width: 100%;
    padding-top: 25px;
}

.dashboard .dashboard-content-section .login-form form select,
.user .user-content-section .login-form form select{
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #fff;
    background-image: url("../../assets/icons/downArrow.svg")!important;
    background-repeat: no-repeat;
    background-position-x: 90%;
    background-position-y: 50%;

}

.dashboard .dashboard-content-section .login-form form .submit-botton p,
.dashboard .dashboard-content-section .login-form form .submit-botton a,
.user .user-content-section .login-form form .submit-botton p,
.user .user-content-section .login-form form .submit-botton a {
    color: #6b7280;
    font-size: 1rem;
}

/*.dashboard .dashboard-content-section .login-form form .submit-botton a,*/
.user .user-content-section .login-form form .submit-botton a {
    padding-right: 6px;
    padding-left: 6px;
    text-decoration: underline;
    text-align: center;
    width: 194px;
}

/*.dashboard .dashboard-content-section .login-form form .submit-botton a:last-child,*/
.user .user-content-section .login-form form .submit-botton a:last-child{
    color: #2563eb;
}

h1{
    margin-top: 1.7rem;
    margin-bottom: 1.7rem;
    color: #357fd8;
    font-weight: bold;
    font-weight: 700;
    font-size: 1.6rem;
}

h2.forgotpass_page_subtitle {
    color: #00061d;
    margin-top: 0;
    margin-bottom: 1.2rem;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: inherit;
}

@media only screen and (min-width: 996px) {
    .user .user-content-section .logo {
        text-align: left;
    }

    .user .user-content-section {
        margin-top: 50px;
        padding-left: 10%;
        height: 100vh;
        background-image: url('../../assets/images/loginBackground.png');
        background-size: cover;
        background-position: right;
        background-repeat: no-repeat;
        display: grid;
        align-items: center;
    }

   /* .dashboard .dashboard-content-section .login-form form .submit-botton,*/
    .user .user-content-section .login-form form .submit-botton {
        display: grid;
        justify-items: right;
        width: 100%;
        padding-top: 0px;
    }

    h1{
        margin-top: 1.2rem;
        margin-bottom: 1.2rem;
        font-weight: 700;
        line-height: 4rem;
        font-size: 2.3rem;
    }
}

@media only screen and (min-width: 766px) and (max-width: 996px) {
    .user .user-content-section{
        display: grid;
        justify-items: center;
    }

  /* .dashboard .dashboard-content-section .login-form form .submit-botton,*/
    .user .user-content-section .login-form form .submit-botton {
        display: grid;
        justify-items: center;
    }

    .user .user-content-section .user-elements{
        padding-left: 15%;
        padding-right: 15%;
    }

    h1{
        margin-top: 1.2rem;
        margin-bottom: 1.2rem;
        font-weight: 700;
        line-height: 4rem;
        font-size: 2.3rem;
    }
}



/* Form */
.dashboard .dashboard-content-section .login-form form .input-info,
.user .user-content-section .login-form form .input-info{
    width: 100%;
    outline-color: #4b9c4e;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    border: 1px solid #8d8d8d;
    padding-left: 25px;
    height: 55px;
    padding-top: 15px;
}

.dashboard .dashboard-content-section form .error-message,
.user .user-content-section .login-form form .error-message{
    z-index: 999;
    position: absolute;
    font-size: 12px;
    font-weight: bold;
    padding-left: 25px;
    margin-top: -3px;
}

.dashboard .dashboard-content-section .login-form form .error,
.user .user-content-section .login-form form .error{
    border:1px solid red!important;
    outline-color: red;
}

.dashboard .dashboard-content-section .login-form form .success,
.user .user-content-section .login-form form .success{
    border:1px solid green!important;
    outline-color: green;
}

/* display del Label & placeholder */
.dashboard .dashboard-content-section .login-form form fieldset,
.user .user-content-section .login-form form fieldset{
    position:relative;
    margin-bottom: 18px;
}

.dashboard .dashboard-content-section .login-form  form fieldset .label-element,
.user .user-content-section .login-form form fieldset .label-element{
    position:absolute;
    top:5px;
    left:5px;
    font-size:12px;
    color:#666;
    padding-left: 25px;
    font-weight: bold;
}

.dashboard .dashboard-content-section .success
.user .user-content-section .success{
    background-color: #ffffff;
}


